.header {
  .navbar {
    .container {
      border-bottom: 1px solid $black;
    }
  }
  .nav-link,
  .navbar-brand {
    transition: all .5s;
    &:hover,
    &:focus,
    &:active {
      color: $green;
    }
  }
}
