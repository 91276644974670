@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap";
@import "../../node_modules/bootstrap/scss/functions";
@import "./variables";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

@import "./mixins";

@import "./base";
@import "./header";
@import "./index";
@import "./projects";
