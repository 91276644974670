.links-container {
  margin-top: 50px;
  .links-link {
    color: $forest-green;
    text-decoration: none;
    .links-text {
      font-family: $headings-font-family;
      font-size: 3rem;
      font-weight: 700;
      opacity: .75;
      @include media-breakpoint-only(md) {
        font-size: 2rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 4rem;
      }
    }
    &:hover,
    &:focus,
    &:active {
      .links-text {
        opacity: 1;
      }
    }
  }
}
.links-bird {
  .bird {
    height: 0;
    padding-top: 88.2%;
    background: url("../../vendors/images/header/crow_silent.png") no-repeat;
    background-size: contain;
    &.bird-right {
      @include flip("H");
    }
  }
  .bird-speech {
    @include flip("V");
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    clear: both;
    visibility: hidden;
    background: $white;
    border: 3px solid $black;
    border-radius: 10px;
    &::before {
      position: absolute;
      bottom: -50px;
      width: 90px;
      height: 50px;
      content: "";
    }
    &.bird-speech-left {
      margin: 160px 10px 10px 0;
      &::before {
        left: 0;
        border-radius: 0 0 100%;
        box-shadow: -2px -2px 0 0 #000 inset, -23px 0 0 0 $white inset, -25px -2px 0 0 #000 inset;
      }
    }
    &.bird-speech-right {
      margin: 160px 0 10px 10px;
      &::before {
        right: 0;
        border-radius: 0 0 0 100%;
        box-shadow: 2px -2px 0 0 #000 inset, 23px 0 0 0 $white inset, 25px -2px 0 0 #000 inset;
      }
    }
    .bird-speech-text {
      @include flip("V");
      margin: 0;
      font-family: $headings-font-family;
    }
  }
  &:hover {
    .bird {
      background-image: url("../../vendors/images/header/crow_talking.png");
    }
    .bird-speech {
      visibility: visible;
    }
  }
}
