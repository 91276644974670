@mixin flip($axis) {
  $scale: scaleX(-1);

  @if $axis == "V" {
    $scale: scaleY(-1);
  }

  -ms-filter: "Flip#{$axis}";
  filter: Flip#{$axis};
  -moz-transform: $scale;
  -o-transform: $scale;
  -webkit-transform: $scale;
  transform: $scale;
}
