.card-project {
  height: 100%;

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-meta--technology {
    color: $green;

    & + .card-meta--technology {
      &::before {
        content: ' | ';
      }
    }
  }

  .card-meta--company {
    & + .card-meta--company {
      &::before {
        content: ' | ';
      }
    }
  }
}

.project {
  .project-meta--technology {
    color: $green;

    & + .project-meta--technology {
      &::before {
        content: ' | ';
      }
    }
  }

  .project-meta--company {
    & + .project-meta--company {
      &::before {
        content: ' | ';
      }
    }
  }
}
