a {
  text-decoration: none;
}

.btn-primary {
  --bs-btn-bg: #{$green};
  --bs-btn-border-color: #{$green};
  --bs-btn-hover-bg: #{shade-color($green, 10%)};
  --bs-btn-hover-border-color: #{shade-color($green, 10%)};
  --bs-btn-focus-shadow-rgb: #{$green-rgb};
  --bs-btn-active-bg: #{shade-color($green, 20%)};
  --bs-btn-active-border-color: #{shade-color($green, 20%)};
}

.btn-outline-secondary {
  --bs-btn-color: #{$red};
  --bs-btn-border-color: #{$red};
  --bs-btn-hover-color: #{$white};
  --bs-btn-hover-bg: #{shade-color($red, 10%)};
  --bs-btn-hover-border-color: #{shade-color($red, 10%)};
  --bs-btn-focus-shadow-rgb: #{$red-rgb};
  --bs-btn-active-color: #{$red};
  --bs-btn-active-bg: #{shade-color($red, 20%)};
  --bs-btn-active-border-color: #{shade-color($red, 20%)};
  transition: all .5s;
}

.text-heavy {
  max-width: 720px;
  margin: 0 auto;
  text-align: justify;
}

.lead {
  font-size: calc(1.5rem + 1.5vw);
  font-weight: 700;
  color: $green;
  @include media-breakpoint-up(xl) {
    font-size: 2.5rem;
  }
}

.sublead {
  font-size: calc(1.325rem + .9vw);
  font-weight: 700;
  color: $red;
  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}
