// Colors
$black: #1c1d21;
$white: #efefef;
$cream: #f2e3bc;
$green: #4a7856;
$forest-green: #0b3b0b;
$red: #c84630;

$black-rgb: rgb(28, 29, 33);
$white-rgb: rgb(239, 239, 239);
$cream-rgb: rgb(242, 227, 188);
$green-rgb: rgb(74, 120, 86);
$forest-green-rgb: rgb(11, 59, 11);
$red-rgb: rgb(200, 70, 48);

$light: $white;

// Bootstrap
$primary: $green;
$font-family-base: "Montserrat", sans-serif;
$headings-font-weight: 700;
$headings-color: $green;
$body-color: $black;
$link-color: $green;
$link-hover-color: shade-color($green, 30%);
$card-border-radius: 1rem;
